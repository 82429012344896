@font-face {
  font-family: "uicon";
  src: url("../fonts/uicon/uicon.eot?t=1696266411242"); /* IE9*/
  src:
    url("../fonts/uicon/uicon.eot?t=1696266411242#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("../fonts/uicon/uicon.woff2?t=1696266411242")
      format("woff2"),
    url("../fonts/uicon/uicon.woff?t=1696266411242") format("woff"),
    url("../fonts/uicon/uicon.ttf?t=1696266411242") format("truetype"),
    /* chrome, firefox, opera, Safari, Android, iOS 4.2+*/
      url("../fonts/uicon/uicon.svg?t=1696266411242#uicon") format("svg"); /* iOS 4.1- */
}

[class^="uicon-"],
[class*=" uicon-"] {
  font-family: "uicon" !important;
  font-size: inherit;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.uicon-apple:before {
  content: "\ea01";
}
.uicon-arrow-down:before {
  content: "\ea02";
}
.uicon-arrow-left:before {
  content: "\ea03";
}
.uicon-arrow-right:before {
  content: "\ea04";
}
.uicon-arrow-up:before {
  content: "\ea05";
}
.uicon-facebook:before {
  content: "\ea06";
}
.uicon-google:before {
  content: "\ea07";
}
.uicon-info-bubble:before {
  content: "\ea08";
}
.uicon-info:before {
  content: "\ea09";
}
.uicon-sesamy:before {
  content: "\ea0a";
}
.uicon-spinner-circle:before {
  content: "\ea0b";
}
.uicon-spinner-inner:before {
  content: "\ea0c";
}
